import React from 'react';
import {useTranslation} from "react-i18next";
import ApiSkills from "../service/api/api_skills";
import ApiCertificate from "../service/api/api_certificate";

function SidebarSection() {

    const { t, i18n } = useTranslation()

    const gaEventTracker = (text) => {
    }

    return (
        <div id="sidebar-section" className="sticky-top p-1">

            <div className="p-5 position-absolute" style={{top: '-50px'}}>
                <br/>
                <img src="/assets/img/me/photo.png" alt="my photo" className={'img-fluid shadow'}
                     style={{
                         borderRadius: '20px'
                     }}
                />
            </div>
            <div className="short-contacts bg-white text-center shadow p-2" style={{
                marginTop: '150px',
                borderRadius: '20px'
            }}>
                <p style={{
                    paddingTop: '120px'
                }}
                   className={'text-center'}
                >
                    <h5 className={'text-dark display-'}>
                        {t('me.name')}
                    </h5>
                    <samll className="text-secondary">
                        {t('me.position')}
                    </samll>
                </p>

                <div className="d-flex align-items-center justify-content-between p-3 pt-">

                    <a onClick={() => gaEventTracker('social-github')} href="https://github.com/yarikthe">
                        <i
                            className="bi-github" role="img" aria-label="GitHub"></i>
                    </a>
                    <a onClick={() => gaEventTracker('social-facebook')}
                       href="https://www.facebook.com/yarik.lukyanchuk/">
                        <i className="bi-facebook"
                           role="img"
                           aria-label="Facebook"></i>
                    </a>
                    <a className={'bg-light'} onClick={() => gaEventTracker('social-instagram')}
                       href="https://www.instagram.com/yariklukyanchuk/">
                        <i className="bi-instagram"
                           role="img"
                           aria-label="Instagram"></i>
                    </a>
                    <a onClick={() => gaEventTracker('social-linkedin')}
                       href="https://www.linkedin.com/in/yaroslav-lukyanchuk-a028481ab/">
                        <i
                            className="bi-linkedin" role="img" aria-label="LinkedIn"></i>
                    </a>
                    {/*<a onClick={() => gaEventTracker('social-twitter')}*/}
                    {/*   href="https://twitter.com/yariklukyanchuk"><i className="bi-twitter" role="img"*/}
                    {/*                                                 aria-label="Twitter"></i> </a>*/}

                    <a onClick={() => gaEventTracker('links-website')}
                       href="https://yaroslav.lukyanchuk.com/links">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-three-dots" viewBox="0 0 16 16">
                            <path
                                d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
                        </svg>
                    </a>
                </div>

                <div className="p-2 pt-0 m-3 mb-4 row text-start" style={{
                    backgroundColor: '#eeeeee',
                    borderRadius: '20px'
                }}>
                    <a onClick={() => gaEventTracker('address')} href="#" className={'p-2'}>
                        <i
                            className="bi-pin-map-fill m-1" role="img"
                            aria-label="Location"></i>
                        {t('contact.location.name')}, 10001
                    </a>

                    <a onClick={() => gaEventTracker('mail')} href="malito:yarik@lukyanchuk.com"
                       className={'p-2'}>
                        <i
                            className="bi-envelope m-1" role="img" aria-label="Email"></i>
                        yarik@lukyanchuk.com
                    </a>
                    <a onClick={() => gaEventTracker('call')}
                       href="tel:+380980751357" className={'p-2'}>
                        <i className="bi-phone m-1" role="img"
                           aria-label="Phone"></i>
                        +380
                        (98) 075 13 57
                    </a>
                    <a onClick={() => gaEventTracker('website')} href="https://lukyanchuk.com"
                       className={'p-2'}>
                        <i className="bi-globe m-1" role="img"
                           aria-label="Website"></i>
                        https://lukyanchuk.com
                    </a>

                </div>

                <a onClick={() => gaEventTracker('download')} download
                   href={"/assets/files/cv_lukyanchuk_" + i18n.language + ".pdf"}
                   className={'btn btn-dark rounded-pill p-3 w-75 mb-3'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-download" viewBox="0 0 16 16">
                        <path
                            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                        <path
                            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                    </svg>
                    <small className={'m-1'}>{t('title.download')} CV</small>
                </a>
            </div>
        </div>
    );
}

export default SidebarSection;


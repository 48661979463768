import React from 'react';
import ReactDOM from 'react-dom';
import i18next from "i18next";

function CardPortfolio(props) {

    const getLanguage = () => i18next.language || window.localStorage.i18nextLng

    var _title = props.project.name[getLanguage()];
    var _category = props.project.service.name[getLanguage()];

    return (

        <div className="col-12 col-md-6 p-2">
            <a
                style={{
                    borderRadius: '20px'
                }}
                href={'https://lukyanchuk.com/portfolio/' + props.project.slug}
                className={'shadowPortfolio border d-flex flex-column align-items-start'}>

                <img
                    style={{
                        width: '100%',
                        height: '170px',
                        objectFit: 'cover',
                        borderRadius: '20px'
                    }}
                    src={props.project.image.url} alt="image" className={'img-fluid portfolio-img'}/>

                <div className="text-start p-3 pt-2 pb-2">

                    <div className="d-flex align-items-center">
                        <small className={'text-secondary'}>#{_category}</small>

                        {
                            props.project.budget > 0 ?

                                <div className="mytooltip">
                                    <small className="text-success p-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em"
                                             fill="currentColor"
                                             className="bi bi-coin" viewBox="0 0 16 16">
                                            <path
                                                d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z"/>
                                            <path
                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path
                                                d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                                        </svg>
                                    </small>

                                    <span className="tooltiptext">Commercial project</span>

                                </div>
                                :
                                <div className="mytooltip">
                                    <small className="text-dark p-2">

                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                             viewBox="0 0 24 24" height="1.5em" width="1.5em"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                                            <path
                                                d="M12 3L1 9l4 2.18v6L12 21l7-3.82v-6l2-1.09V17h2V9L12 3zm6.82 6L12 12.72 5.18 9 12 5.28 18.82 9zM17 15.99l-5 2.73-5-2.73v-3.72L12 15l5-2.73v3.72z"></path>
                                        </svg>

                                    </small>
                                    <span className="tooltiptext">Project for Experience</span>
                                </div>
                        }
                    </div>
                    <h5 className={''}>{_title}</h5>
                </div>

            </a>
        </div>

    );
}

export default CardPortfolio;
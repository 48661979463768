import React from 'react';
import {useTranslation} from "react-i18next";
import ApiSkills from "../service/api/api_skills";

function SkillsSection() {

    const { t, i18n } = useTranslation()

    return (<div id="skills-section">

        <div className="shadow p-4 mt-4 bg-white" style={{
            borderRadius: '20px'
        }}>
            <h2>{t('title.skills')}</h2>

            <ApiSkills/>

        </div>

    </div>);
}

export default SkillsSection;

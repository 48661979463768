import React from 'react';
import {useTranslation} from "react-i18next";
import ApiSkills from "../service/api/api_skills";
import ApiCertificate from "../service/api/api_certificate";
import ApiPortfolio from "../service/api/api_portfolio";

function PortfolioSection() {

    const { t, i18n } = useTranslation()

    return (<div id="portfolio-section">

        <div className="shadow p-4 mt-4 bg-white" style={{
            borderRadius: '20px'
        }}>
            <h2>{t('title.portfolio')}</h2>
            <ApiPortfolio/>
        </div>

    </div>);
}

export default PortfolioSection;


import React from 'react';
import {useTranslation} from "react-i18next";
import ApiSkills from "../service/api/api_skills";
import ApiCertificate from "../service/api/api_certificate";

function CertificatesSection() {

    const { t, i18n } = useTranslation()

    return (<div id="certificates-section">

        <div className="shadow p-4 mt-4 bg-white" style={{
            borderRadius: '20px'
        }}>
            <h2>{t('title.certification')}</h2>

            <ApiCertificate/>

        </div>

    </div>);
}

export default CertificatesSection;


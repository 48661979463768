import React, {useEffect, useState} from 'react'
import Section from "../components/section/section";
import axios from "axios";
import Reaptcha from "reaptcha";
import {useTranslation} from "react-i18next";
import Main from "./main";

function Auth(props) {

    const {t, i18n} = useTranslation();

    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');

    const [message, setMessage] = useState('');

    const [isNew, setNew] = useState(false);

    const [verified, setVerified] = useState(false);
    const [recaptcha, setRecaptcha] = useState(false);

    const toggleNew = () => {
        setNew(!isNew)
    }

    const onVerify = () => {
        setVerified(true)
    };

    const sendGetAccess = (e) => {

        e.preventDefault();

        try {
            if (!verified) {
                setMessage('ReCaptcha Error')
            }

            axios.post('https://api.lukyanchuk.com/api/front-end/resume/access', {
                email,
                code,
                "g-recaptcha-response": verified
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    // "Authorization" : "Bearer " + USER.token,
                }
            }).then(response => {

                console.log('data ' + response.data);

                setMessage(response.data.message);
                setVerified(false)
                setRecaptcha(false);

                if (response.data.data.email) {
                    localStorage.setItem('id', response.data.data.id);
                    localStorage.setItem('email', response.data.data.email);
                    localStorage.setItem('isAuth', true);
                }

                toggleNew()
                window.location.reload(true);

            }).catch(e => {
                console.log(e)
            });

        } catch (e) {
            console.log(e)
        }
    }

    // if (localStorage.getItem('isAuth')) {
    //     return <Main />;
    // }

    return (

        <div className="vh-100 d-flex justify-content-center align-items-center">


                <div className={'get-access col-md-3 col-12 position-absolute1 bg-white p-5 shadow-lg'} style={{
                    borderRadius: "30px",
                    opacity: '0.7'
                }}>
                    <p>
                        {message}
                    </p>
                    <h1 className={'p-2'}>{isNew ? t('get.code') : t('get.access')}</h1>

                    <form onSubmit={sendGetAccess} className={'d-flex flex-column p-2'}>

                        <div className={'d-flex'}>
                            {
                                isNew ?
                                    <div className="email col-md-10">
                                        <input type="email" required value={email}
                                               onChange={(e) => setEmail(e.target.value)} className={'form-control'}
                                               placeholder={'Email'}/>
                                    </div>
                                    :
                                    <div className="email col-md-10">
                                        <input type="password" required value={code} name={'code'} id={'code'}
                                               onChange={(e) => setCode(e.target.value)} className={'form-control'}
                                               placeholder={t('code')}/>
                                    </div>
                            }


                            <button id={'btn-send'} type={'submit'} className={'btn btn-dark col-md-2'}>
                                {isNew ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                              className="bi bi-send" viewBox="0 0 16 16">
                                        <path
                                            d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                                    </svg> :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                    </svg>}
                            </button>
                        </div>

                        <br/>

                        <Reaptcha
                            // size="invisible"
                            ref={e => (setRecaptcha(e))}
                            sitekey="6LcnW98UAAAAAKwKipkUQedRZmocs5SmqlMzslBZ"
                            onVerify={onVerify}
                            onExpire={true}
                        />

                    </form>
                    <button onClick={toggleNew}
                            className={'btn btn-default text-secondary'}>{isNew ? t('input.code') : t('no.code')}</button>
                </div>

        </div>

    );
}

export default Auth;
import React from 'react';
import {useTranslation} from "react-i18next";
import ApiSkills from "../service/api/api_skills";
import ApiCertificate from "../service/api/api_certificate";

function InfoSection() {

    const { t, i18n } = useTranslation()

    return (<div id="info-section">

        <div className="shadow p-4 bg-white" style={{
            borderRadius: '20px'
        }}>
            <div className="name d-flex justify-content-between align-items-center">
                <div className={'"col-md-9'}>
                    <samll className="text-secondary">
                        <>v2.1 {t('info.updated')} 26.04.2024</>
                    </samll>
                    <h2 className={'text-dark display-4'}>
                        {t('resume')}
                    </h2>
                </div>
                <div className="col-md-3 text-end d-flex">

                    <img src="https://lukyanchuk.com/assets/logo/logo.png"
                         alt="QR Code to https://lukyanchuk.com" className="img-fluid w-50"/>
                    <img src="/assets/img/qr-code/my-site-qr-code.png"
                         alt="QR Code to https://lukyanchuk.com" className="img-fluid w-50"/>

                </div>
            </div>


            <div className="d-flex flex-wrap">

                <div className="item">
                    <samll className="text-secondary">
                        {t('bio')}
                    </samll>
                    <br/>
                    <p className={'lead text-dark'}>
                        {t('me.description')}
                        <a href="#moreInfo" className={'m-1'}>
                            <small className="text-secondary">
                                <i className="bi-info-circle"
                                   role="img"
                                   aria-label="info"></i>   {t('more.info')}
                            </small>
                        </a>
                    </p>
                </div>

                <div className="item">
                    <small className="text-secondary">
                        {t('info.specialization.title')}:
                    </small>
                    <br/>
                    <span className="text-dark lead">
                                    {t('info.specialization')}
                                </span>
                </div>

                <div className="item">
                    <small className="text-secondary">
                        {t('info.type-employment.title')}:
                    </small>
                    <br/>
                    <span className="text-dark lead">
                                    {t('info.type-employment')}
                                </span>
                </div>

                <div className="item">
                    <small className="text-secondary">
                        {t('info.ready-work.title')}:
                    </small>
                    <br/>
                    <span className="text-dark lead">
                                    {t('info.ready-work')}
                                </span>
                </div>

                <div className="item">
                    <small className="text-secondary">
                        {t('info.location.title')}:
                    </small>
                    <br/>
                    <span className="text-dark lead">
                                    {t('info.location')}
                                </span>
                </div>

                <div className="item">
                    <small className="text-secondary">
                        {t('info.position.title')}:
                    </small>
                    <br/>
                    <span className="text-dark lead">
                                    {t('info.position')}
                                </span>
                </div>

                <div className="item">
                    <small className="text-secondary">
                        {t('info.stack.title')}:
                    </small>
                    <br/>
                    <span className="text-dark lead">
                                    Laravel, Flutter, React JS
                                </span>
                </div>

                <div className="item">
                    <small className="text-secondary">
                        {t('info.language.title')}:
                    </small>
                    <br/>
                    <span className="text-dark lead">
                                    <img src="/assets/img/lang/ua.png" alt="Ukraine" height="15" className={'rounded m-1'}/>
                        {t('language.ua')},
                                    <img src="/assets/img/lang/uk.png" alt="English" height="15" width={'25'}
                                         className={'rounded m-1'}/>
                        {t('language.uk')}
                                </span>
                </div>

            </div>



            <div className="item">
                <small className="text-secondary">
                    {t('info.what-i-do.title')}:
                </small>

                <div className="d-flex flex-wrap">

                    <div className="col-md-4 p-1">
                        <div className="p-">
                            <h5>
                                {t('info.what-i-do.title.1')}
                            </h5>
                            <small>
                                {t('info.what-i-do.text.1')}
                            </small>
                        </div>
                    </div>

                    <div className="col-md-4 p-1">
                        <div className="p-">
                            <h5>
                                {t('info.what-i-do.title.2')}
                            </h5>
                            <small>
                                {t('info.what-i-do.text.2')}
                            </small>
                        </div>
                    </div>

                    <div className="col-md-4 p-1">
                        <div className="p-">
                            <h5>
                                {t('info.what-i-do.title.3')}
                            </h5>
                            <small>
                                {t('info.what-i-do.text.3')}
                            </small>
                        </div>
                    </div>

                </div>

                <p className={'mt-2'}>
                    {t('info.link')} <a href="https://yaroslav.lukyanchuk.com/about" target={'_blank'} className={'text-secondary'}> <i className={'bi bi-arrow-right'}></i> yaroslav.lukyanchuk.com/about</a>
                </p>

            </div>
        </div>

    </div>);
}

export default InfoSection;


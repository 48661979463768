import React from 'react';
import Section from "../section/section";
import {useTranslation} from "react-i18next";
import Language from "../language/language";
import Auth from "../../pages/auth";

function Header(props) {

    const {t, i18n} = useTranslation();

    const _isAuth = localStorage.getItem('isAuth');
    const _email = localStorage.getItem('email');

    const logout = () => {

        localStorage.clear();
        window.location.href = '/';
        // localStorage.setItem('isAuth', false)
    }

    return (
        <header className={'container-fluid bg-'}>
            <div className="header-block mt-0 p-3 d-flex justify-content-between align-items-center border-bottom">
                <div className="logo col-md-2 text-start">
                    <img src="https://lukyanchuk.com/assets/logo/logo.png" alt="logo"
                         className={'img-fluid w-25'}/>
                </div>
                <div className="title col-md-8 text-center p-md-0 p-5">
                    <h1 className={'display-2'}>
                        {t('welcome')}
                    </h1>
                </div>
                <div className="offer col-md-2 text-center">

                    {
                        !_isAuth ? <div className={'d-flex align-items-center'}>
                                <div className={'p-2'}><a href={'mailto:yarik@lukyanchuk.com?subject=JobOffer'}
                                                          target={'_blank'} className="btn btn-dark p-3 rounded-pill">
                                    {t('job.offer.title')}
                                </a>
                                </div>
                                <div className={'p-2'}><Language/></div>
                            </div>
                            : <div className={'d-flex align-items-center'}>

                                <div className={'p-2'}>
                                    <img className={'rounded-circle'} width={'45'}
                                         src="https://p7.hiclipart.com/preview/336/946/494/avatar-user-medicine-surgery-patient-avatar-thumbnail.jpg"
                                         alt=""/>
                                </div>
                                <div className={'text-start p-2'}>
                                    <small className={'lead'}>{_email ?? 'Welcome'}</small>
                                    <br/>
                                    <button className="btn text-danger" onClick={logout}>{t('logout')}</button>
                                </div>
                                <div className={'p-2'}><Language/></div>
                            </div>
                    }

                </div>
            </div>
        </header>
    );
}

export default Header;
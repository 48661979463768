import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Section from "../../components/section/section";
import {useTranslation} from "react-i18next";
import API_URL from "./config";
import CardCertification from "../../components/card/card_certification";
import CardSkill from "../../components/card/card_skills";

const ApiSkills = (props) => {

    const { t, i18n } = useTranslation();
    const [skills, setSkills] = useState([]);
    const [load, setLoad] = useState(true);

    useEffect(()=> {

        axios.get(
            // 'https://storage.lukyanchuk.com/storage/json/skills.json'
            // 'https://api.lukyanchuk.com/api/front-end/resume/skills'
            'https://api.lukyanchuk.com/api/v2/frontend/resume?json=skills'
        ).then(function (response) {
            const getSkills = response.data;
            setSkills(getSkills.data);
            console.log(getSkills);
            setLoad(false);
        }).catch(err => {
            // In case it errors.
            console.log(err);
        });

    }, []);

    return (

        load ? 'Loading...' :
        <div className={'d-flex flex-wrap'}>
            {
                skills.map((item, index) => <CardSkill item={item} />)
            }
        </div>

    )

}

export default ApiSkills

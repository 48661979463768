import React from 'react';

function CardSkill(props) {

    return (
        <div className="p-1">
            <div className="p-2"  style={{
                backgroundColor: '#eeeeee',
                borderRadius: '7px'
            }}>

                <small className={'text-secondary'}>
                    <img
                        style={{
                            width: '20px',
                            height: '20px',
                            objectFit: 'contain'
                        }}
                        src={'https://storage.lukyanchuk.com/storage/json/img/skills/' + props.item.logo} alt={props.item.logo} className={
                        'm-1 rounded'
                    }/>
                    {
                        props.item.name
                    }
                </small>
            </div>
        </div>
    );
}

export default CardSkill;
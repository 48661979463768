import React from 'react';
import {useTranslation} from "react-i18next";
import ApiSkills from "../service/api/api_skills";
import ApiCertificate from "../service/api/api_certificate";

function MoreInfoSection() {

    const { t, i18n } = useTranslation()

    const _data = [
        {
            "paragraph1": {
                "en": "I will objectively assess my capabilities to solve the tasks. I have a constant desire to master and learn new technologies, tools, frameworks, programming languages and improve every day.",
                "ua": "Об'єктивно оціню свої можливості для вирішення поставлених завдань. Маю постійне бажання освоювати та\n" +
                    "                        вивчати нові технології, інструменти, фреймворки, мови програмування та вдосконалюватись щодня.",
            },
            "personal_qualities": {
                "en": [
                    "Punctuality",
                    "Responsibility",
                    "Tolerance",
                    "Non-conflict",
                    "Organization"
                ],
                "ua": [
                    "Пунктуальність",
                    "Відповідальність",
                    "Толерантність",
                    "Безконфліктність",
                    "Організованість"
                ]
            },
            "advantages": {
                "title": {
                    "en": "Advantages",
                    "ua": "Переваги"
                },
                "en": [
                    "Focusing on the case",
                    "Ability to work both independently and as part of a team",
                    "Self-education and professional development, expansion of knowledge and skills",
                    "Meeting the deadline",
                    "Ready to compromise",
                    "I adequately perceive constructive criticism addressed to me",
                    "Independence of finding and solving bugs and errors",
                ],
                "ua": [
                    "Фокусування на справі",
                    "Здатність працювати як самостійно, так і в складі команди",
                    "Самоосвіта та підвищення кваліфікації, розширення знань та навичок",
                    "Дотримання дедлайну",
                    "Готовий йти на компроміс",
                    "Адекватно сприймаю коструктивну критику у свій адрес",
                    "Самостійність пошуку та вирішення багів, помилок",
                ],
            },
            "disadvantages": {
                "title": {
                    "en": "Disadvantages",
                    "ua": "Недоліки"
                },
                "en": [
                    "I like to work at night",
                    "A project that does not have a clear presentation is boring",
                    "It is difficult to force yourself to enter the flow of work, to adjust to work after a long break",
                    "Perfectionism",
                    "Attention to detail",

                ],
                "ua": [
                    "Люблю працювати в нічний час",
                    "Набридає проект який не має чіткого представлення",
                    "Тяжко змусити себе увійти в потік роботи, налаштуватись на роботу після тривалої перерви",
                    "Перфекціонізм",
                    "Увага до деталей",

                ],
            },
            "resume_available": {
                "en": "My resume is also available at ",
                "ua": "Моє резюме також доступне на "
            }
        }
    ];

    const _personal_qualities = i18n.language === 'ua' ? _data[0]['personal_qualities']['ua'] : _data[0]['personal_qualities']['en'];

    const _advantages = i18n.language === 'ua' ? _data[0]['advantages']['ua'] : _data[0]['advantages']['en'];
    const _disadvantages = i18n.language === 'ua' ? _data[0]['disadvantages']['ua'] : _data[0]['disadvantages']['en'];

    return (<div id="more_info-section">

        <div id={'moreInfo'} className="vh-100 d-flex align-items-center justify-content-center">
            <div className="shadow p-4 mt-4 bg-white" style={{
                borderRadius: '20px'
            }}>
                <h2>{t('more.info')}</h2>
                <div className="">
                    <p>
                        {
                            i18n.language === 'ua' ? _data[0]['paragraph1']['ua'] : _data[0]['paragraph1']['en']
                        }
                    </p>

                    <div className="row">
                        <div className="col-md-6">
                            <h5>
                                {
                                    i18n.language === 'ua' ? _data[0]['advantages']['title']['ua'] : _data[0]['advantages']['title']['en']
                                }
                                (+)</h5>
                            <ul>
                                {
                                    _advantages.map((item, i) => <li>
                                            {item}
                                        </li>
                                        )
                                }
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <h5>
                                {
                                    i18n.language === 'ua' ? _data[0]['disadvantages']['title']['ua'] : _data[0]['disadvantages']['title']['en']
                                }
                                (-)</h5>
                            <ul>
                                {
                                    _disadvantages.map((item, i) => <li>
                                            {item}
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>

                    <h5>{t('personal.qualities')}</h5>
                    <div className="d-flex flex-wrap">

                        {
                            _personal_qualities.map((item, i) =>  <div className="p-1">
                                <div className="p-2"  style={{
                                    backgroundColor: '#e5e4e4',
                                    borderRadius: '7px'
                                }}>

                                    <small className={'text-secondary'}>
                                        {item}
                                    </small>
                                </div>
                            </div>)
                        }

                    </div>

                    <p className={'mt-3'}>
                        {
                            i18n.language === 'ua' ? _data[0]['resume_available']['ua'] : _data[0]['resume_available']['en']
                        } <a href="https://work.ua" target={'_blank'}>work.ua</a>, <a href="https://djinni.co/q/d5d9bccef0/" target={'_blank'}>djini.co</a>
                    </p>
                </div>
            </div>
        </div>

    </div>);
}

export default MoreInfoSection;


import React from 'react';
import i18next from "i18next";

function CardEducation(props) {

    const item = props.item;

    const getLanguage = () => i18next.language || window.localStorage.i18nextLng

    var _title = JSON.parse(item.name)[getLanguage()];
    var _faculty = JSON.parse(item.faculty)[getLanguage()];
    var _level = JSON.parse(item.level)[getLanguage()];

    return (
        <div className="col-md-12 col-12 mb-2">

            <div className="shadow1 border-bottom p-2" style={{
                backgroundColor: '#eeeeee',
                borderRadius: '7px'
            }}>

                <h6>
                    {_level} <br/>
                    <small className="text-secondary">
                        {_faculty} (<small className="text-secondary">
                        {item.begin.split('-')[0]} - {item.graduated.split('-')[0]}
                    </small>)
                    </small>
                </h6>
                <small className={'text-secondary d-flex align-items-center'}>
                    <img
                        style={{
                            width: '50px',
                            height: '50px',
                            objectFit: 'contain'
                        }}
                        src={item.logo} alt="logo" className="img-fluid m-1"/> {_title}
                </small>
            </div>

        </div>
    );
}

export default CardEducation;
import React from 'react';
import Language from "../language/language";
import { useTranslation } from 'react-i18next';

function Footer(props) {

    const { t, i18n } = useTranslation();

    return (
        <footer className="container-fluid bg-dark text-white p-5 pb-4 rounded fixed-bottom1">

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <p className="copyright">
                        {t('copyright')} © {new Date().getFullYear()}. <a href="https://lukyanchuk.com" className={'text-white'}> {t('me.name')}</a>.
                    </p>
                </div>
                <div>
                    <Language />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
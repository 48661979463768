import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useTranslation} from "react-i18next";
import CardPortfolio from "../../components/card/card_portfolio";

const ApiPortfolio = (props) => {

    const { t, i18n } = useTranslation();
    const [portfolio, setPortfolio] = useState([]);
    const [load, setLoad] = useState(true);

    useEffect(()=> {

        axios.get(
            'https://office.lukyanchuk.com/api/front-end/projects?sort=DESC'
        ).then(function (response) {
            const getPortfolio = response.data;
            setPortfolio(getPortfolio.data);
            console.log(getPortfolio.data);
            setLoad(false);
        }).catch(err => {
            // In case it errors.
            console.log(err);
        });

    }, []);

    const data =  portfolio.slice(0, 5).map((item, index) => <CardPortfolio key={index} project={item}/>);

    return (
        <div className={'d-flex flex-wrap'}>
            {load ? 'Loading...' : data}
            <div className="col-12 col-md-6 p-2 d-flex align-items-center justify-content-center">
                <a href="https://lukyanchuk.com/portfolio"
                   target={'_blank'}
                   className={'btn btn-dark p-3 rounded-pill col-md-6 col-12 shadowPortfolio'}
                >
                    {t('title.view-more')}
                </a>
            </div>
        </div>
    );

}

export default ApiPortfolio;

import React from 'react';
import {useTranslation} from "react-i18next";
import ApiSkills from "../service/api/api_skills";
import ApiCertificate from "../service/api/api_certificate";
import Accordion from "react-bootstrap/Accordion";
import ApiEducation from "../service/api/api_education";

function EducationSection() {

    const { t, i18n } = useTranslation()

    return (<div id="education-section">

        <div className="shadow p-4 mt-4 bg-white" style={{
            borderRadius: '20px'
        }}>
            <h2>

                {t('title.education')}
            </h2>

            <ApiEducation/>

        </div>

    </div>);
}

export default EducationSection;


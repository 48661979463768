import React from 'react';
import {useTranslation} from "react-i18next";

function CardCertification(props) {

    const {t, i18n} = useTranslation();

    const item = props.item;

    return (


        <div className={'col-md-12 col-12 pb-1'}>

            <div className="info-certification d-flex align-items-center">
                <div className="col-md-10">
                    <small className="text-secondary">{item.data}</small>
                    <a href={item.url} target={'_blank'}> <h6 className="">{item.name}</h6> </a>
                </div>
                <div className="col-md-2 text-end">
                    {/*<a href={item.url} download={item.url} className="m-1"><i className="bi bi-download"></i></a>*/}
                    {/*<a href={item.url} target={'_blank'} className="m-1"><i className="bi bi-share"></i></a>*/}
                    <a href={item.url} target={'_blank'} className="m-1"><i className="bi bi-box-arrow-up-right"></i></a>
                </div>
            </div>

        </div>
    );
}

export default CardCertification;
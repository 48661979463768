import React from 'react';
import Moment from "moment";
import moment from "moment";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function CardExperience(props) {

    const getLanguage = () => i18next.language || window.localStorage.i18nextLng

    const { t, i18n } = useTranslation();

    function getDiffDate(startDate, endData)
    {
        var diffMonth =(endData.getTime() - startDate.getTime()) / 1000;
        diffMonth /= (60 * 60 * 24 * 7 * 4);

        var month = Math.abs(Math.round(diffMonth));
        var year = 0;

        if(month > 12)
        {
            var year = Math.abs(Math.ceil(diffMonth / 12));
            var month = Math.abs(Math.round(diffMonth % 12));
        }

        // console.log(props.company + Math.abs(Math.round(diffMonth)));

        return <span> {year == 0 ? '' : year + t('label.year')} {month == 0 ? '' : month + t('label.month')} </span>;
    }

    function monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    var _position = JSON.parse(props.item.position)[getLanguage()];
    var _company = JSON.parse(props.item.company)[getLanguage()];

    var countMonth = monthDiff(
        new Date(
            Moment(props.item.date_start).format('YYYY'),
            Moment(props.item.date_start).format('MM'),
            Moment(props.item.date_start).format('DD')
        ),
        new Date(
            Moment(props.item.date_end).format('YYYY'),
            Moment(props.item.date_end).format('MM'),
            Moment(props.item.date_end).format('DD')
        )
    );

    function getWords(monthCount) {
        function getPlural(number, word) {
            return number === 1 && word.one || word.other;
        }

        var months = { one: 'month', other: 'months' },
            years = { one: 'year', other: 'years' },
            m = monthCount % 12,
            y = Math.floor(monthCount / 12),
            result = [];

        y && result.push(y + ' ' + getPlural(y, years));
        m && result.push(m + ' ' + getPlural(m, months));
        return result.join(' and ');
    }

    return (
        <div className={'col-md-12 col-12 mb-2'}>

            <div className="d-flex justify-content-between shadow1 border-bottom p-2"
                 style={{
                     backgroundColor: '#eeeeee',
                     borderRadius: '7px'
                 }}
                >
                <div className="info-experience">
                    <h6>
                        {_company}
                    </h6>
                    <small>{Moment(props.item.date_start).format('MMMM YYYY')} - {props.item.date_end == '0000-00-00'? t('label.now') : Moment(props.item.date_end).format('MMMM YYYY')} ({getWords(countMonth)})</small>
                    <p className={'mt-1'}>
                        <div dangerouslySetInnerHTML={{ __html: _position}} />
                    </p>
                </div>
            </div>

            {/*<hr/>*/}
        </div>
    );
}

export default CardExperience;